import React, { useEffect, useRef, useState } from "react";
import { ReactTyped } from "react-typed";

import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

import ConnitionImg from "../../assets/image/connitionImg.png";
import ServiceChatbotImg from "../../assets/image/serviceChatbotImg.png";
import ServiceGrid2 from "../../assets/image/serviceGrid2.png";
// import ServiceGrid3 from "../../assets/image/serviceGrid3.png";
import ChatAiIcon from "../../assets/icon/senaAiIcon.png";
import { toSentenceCase } from "../../utils";

let textAnimateStop = false;

const Services = React.forwardRef((props, ref) => {
  const { ragAIData, homeStrapiData = [], question } = props;

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [selectedService, setSelectedService] = useState(
    homeStrapiData?.[0] || {}
  );
  const [selectedButton, setSelectedButton] = useState("button1");
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const descriptionRef = useRef(null);

  // Scroll to the description section
  useEffect(() => {
    if (isDescriptionOpen && descriptionRef.current) {
      textAnimateStop = false;
      descriptionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isDescriptionOpen]);

  const handleServiceClick = (service) => {
    if (selectedService?.heading === service.heading) {
      setIsDescriptionOpen(!isDescriptionOpen);
    } else {
      setSelectedService(service);
      setSelectedButton("button1");
      setIsDescriptionOpen(true);
    }
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
      // Scroll to the description section when subsection is open
      if (descriptionRef.current) {
        descriptionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  // For active the service tab
  useEffect(() => {
    if (ragAIData?.data?.subsection) {
      const matchedService = homeStrapiData.find(
        (service) => service.heading === ragAIData?.data?.subsection
      );
      if (matchedService) {
        textAnimateStop = false;
        setSelectedService(matchedService);
        setSelectedButton("AI");
        setIsDescriptionOpen(true);
        // Scroll to the description section when subsection is open
        if (descriptionRef.current) {
          descriptionRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [ragAIData]);

  // Typed Animation content
  const aiContent = ragAIData?.data?.content?.text || "Not Found";

  return (
    <div
      ref={ref}
      className="w-full border-b border-white relative"
      style={{
        background: "linear-gradient(90deg, #666666 -60%, #000000 80%)",
      }}
      id="serviceSection"
    >
      {/* <div className="absolute top-[20%] left-[0px] z-0">
        <img src={ServiceGrid1} alt="serviceGrid" className="w-[90px]" />
      </div> */}
      <div className="absolute top-[5%] right-[0px] z-0">
        <img src={ServiceGrid2} alt="serviceGrid" className="w-[90px]" />
      </div>

      <div className="sectionContainer max-w-[1468px] responsiveWidth">
        <div className="flex flex-col gap-[30px] py-[47px] md:gap-[40px] lg:gap-[60px] md:py-[80px]">
          <div className="px-[7px] md:px-0 z-[20] flex flex-col justify-between md:flex-row">
            <h2 className="font-sora text-[40px] md:text-[52px] lg:text-[60px] font-semibold leading-[50.4px] md:leading-[65.52px] lg:leading-[75.6px] text-left">
              Services
            </h2>
            {/* <p className="font-jakarta w-[70vw] md:w-[40vw] lg:w-[18vw] text-[16px] md:text-[18px] font-semibold md:leading-[30.8px] md:text-right">
              Sena provides services under three main domains
            </p> */}
          </div>
          <div>
            <div className="flex flex-col md:flex-row gap-5 items-center justify-between px-[7px] md:px-0">
              {homeStrapiData.map((service, index) => (
                <div
                  key={index}
                  onClick={() => handleServiceClick(service)}
                  className={`z-20 cursor-pointer flex items-center justify-around gap-2.5 bg-[#5757571a] p-6 md:p-8 lg:py-[40px] rounded-[25px] border border-white h-fit lg:h-[180px] w-full md:w-[225px] lg:w-[500px] hover:border-[#F7D504] ${
                    selectedService.heading === service?.heading
                      ? "bg-[#666666]"
                      : ""
                  }`}
                  style={{
                    background:
                      selectedService?.heading === service?.heading &&
                      isDescriptionOpen
                        ? "linear-gradient(71.36deg, #000000 31.72%, #666666 131.95%)"
                        : "#5757571a",
                  }}
                  role="presentation"
                >
                  <p className="font-sora text-[#9C9C9C] text-[20px] md:text-[16px] lg:text-[32px] font-semibold leading-tight md:leading-[40.32px] text-left uppercase hover:text-white">
                    {service.heading}
                  </p>
                  <div className="flex justify-end">
                    {isDescriptionOpen ? (
                      selectedService?.heading === service?.heading ? (
                        <FiArrowUpRight size={30} />
                      ) : (
                        <FiArrowDownRight size={30} />
                      )
                    ) : (
                      <FiArrowDownRight size={30} />
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Service Description */}
            {isDescriptionOpen && (
              <div ref={descriptionRef} className="pt-[60px]">
                <div
                  className="flex flex-col md:flex-row items-center gap-10 py-9 px-5 md:py-[38px] md:px-[40px] lg:py-[40px] lg:px-[80px] rounded-[25px]"
                  style={{
                    background:
                      "linear-gradient(71.36deg, #000000 31.72%, #666666 131.95%)",
                  }}
                >
                  {/* <div className="absolute top-[65%] left-[0px] z-0">
                    <img
                      src={ServiceGrid3}
                      alt="serviceGrid"
                      className="w-[90px]"
                    />
                  </div> */}
                  <div className=" z-[20] w-full">
                    <div className="flex justify-between items-center">
                      <h2
                        className={`font-sora text-left text-[20px] md:text-[20px] lg:text-[32px] font-semibold leading-[20px] md:leading-[30px] lg:leading-[40px] transition-opacity duration-1000 ${
                          isTransitioning ? "opacity-0" : "opacity-100"
                        }`}
                      >
                        {selectedService?.heading}
                      </h2>
                      <IoClose
                        size={25}
                        className="cursor-pointer"
                        onClick={() => {
                          if (ref.current) {
                            ref.current.scrollIntoView({
                              behavior: "smooth",
                            });
                          }
                          setIsDescriptionOpen(false);
                        }}
                      />
                    </div>
                    <p
                      className={`font-sora text-[13px] md:text-[16px] font-normal leading-[26.64px] text-left mt-3 transition-opacity duration-1000 ${
                        isTransitioning ? "opacity-0" : "opacity-100"
                      }`}
                    >
                      {selectedService?.description}
                    </p>
                    <div className="mt-10 flex gap-5">
                      <button
                        className={`${
                          selectedButton === "button1"
                            ? "bg-[#F7D504] text-[#1A1A1A]"
                            : "bg-[#1A1A1A] text-white"
                        } text-[13px] md:text-[16px] font-[600] p-3 md:px-[33px] md:py-[16px] rounded-[25px] hover-shadow-yellow transition-opacity duration-1000 ${
                          isTransitioning ? "opacity-0" : "opacity-100"
                        }`}
                        onClick={() => handleButtonClick("button1")}
                      >
                        {selectedService?.sections?.[0]?.name || "No Section"}
                      </button>
                      {selectedService?.sections[1] && (
                        <button
                          className={`${
                            selectedButton === "button2"
                              ? "bg-[#F7D504] text-[#1A1A1A]"
                              : "bg-[#1A1A1A] text-white"
                          }  text-[13px] md:text-[16px] font-[600] p-3 md:px-[33px] md:py-[16px] rounded-[25px]  hover-shadow-yellow transition-opacity duration-1000 ${
                            isTransitioning ? "opacity-0" : "opacity-100"
                          }`}
                          onClick={() => handleButtonClick("button2")}
                        >
                          {selectedService?.sections[1]?.name || "No Section"}
                        </button>
                      )}
                      {selectedService.sections[2] && (
                        <button
                          className={`${
                            selectedButton === "button3"
                              ? "bg-[#F7D504] text-[#1A1A1A]"
                              : "bg-[#1A1A1A] text-white"
                          }  text-[13px] md:text-[16px] font-[600] p-3 md:px-[33px] md:py-[16px] rounded-[25px]  hover-shadow-yellow transition-opacity duration-1000 ${
                            isTransitioning ? "opacity-0" : "opacity-100"
                          }`}
                          onClick={() => handleButtonClick("button3")}
                        >
                          {selectedService?.sections[2]?.name || "No Section"}
                        </button>
                      )}

                      {ragAIData?.data?.subsection ===
                        selectedService?.heading && (
                        <button
                          className={`${
                            selectedButton === "AI"
                              ? "bg-white text-[#1A1A1A]"
                              : "bg-[#1A1A1A] text-white"
                          }  text-[13px] md:text-[16px] font-[600] p-3 md:px-[33px] md:py-[16px] rounded-[25px]  hover-shadow-white transition-opacity duration-1000 ${
                            isTransitioning ? "opacity-0" : "opacity-100"
                          }`}
                          onClick={() => handleButtonClick("AI")}
                        >
                          <img
                            src={ChatAiIcon}
                            alt="chat bot"
                            width={26}
                            height={25}
                            className={`${
                              selectedButton === "AI" ? "invert-0" : "invert"
                            }`}
                          />
                        </button>
                      )}
                    </div>

                    <div className="flex flex-col lg:flex-row gap-5 mt-10">
                      <div className="w-full lg:w-[60%]">
                        <h2
                          className={`font-sora text-left text-[18px] md:text-[20px] lg:text-[28px] font-semibold leading-[20px] md:leading-[30px] lg:leading-[40px] transition-opacity duration-1000 ${
                            isTransitioning ? "opacity-0" : "opacity-100"
                          }`}
                        >
                          {selectedButton === "button1"
                            ? selectedService?.sections?.[0]?.name
                            : selectedButton === "button2"
                            ? selectedService?.sections[1]?.name
                            : selectedButton === "button1"
                            ? selectedService?.sections[2]?.name
                            : toSentenceCase(question) || "Not Found"}
                        </h2>

                        {selectedButton === "AI" && aiContent ? (
                          textAnimateStop ? (
                            <span>{aiContent}</span>
                          ) : (
                            <ReactTyped
                              key={aiContent}
                              strings={[aiContent]}
                              typeSpeed={1}
                              backSpeed={10}
                              showCursor={false}
                              onComplete={() =>
                                (textAnimateStop = !textAnimateStop)
                              }
                            />
                          )
                        ) : (
                          <p
                            className={`font-sora text-[13px] md:text-[16px] font-normal leading-[26.64px] text-left mt-3 transition-opacity duration-1000 ${
                              isTransitioning ? "opacity-0" : "opacity-100"
                            }`}
                          >
                            {selectedButton === "button1"
                              ? selectedService?.sections?.[0]?.description
                              : selectedButton === "button2"
                              ? selectedService?.sections[1]?.description
                              : selectedService?.sections[2]?.description ||
                                "Not Found"}
                          </p>
                        )}
                      </div>
                      <div className="w-full lg:w-[40%]">
                        <img
                          src={
                            selectedButton === "AI"
                              ? ServiceChatbotImg
                              : selectedButton === "button1"
                              ? `${baseUrl}${selectedService?.sections?.[0]?.image?.data?.attributes?.url}`
                              : selectedButton === "button2"
                              ? `${baseUrl}${selectedService?.sections?.[1]?.image?.data?.attributes?.url}`
                              : `${baseUrl}${selectedService?.sections?.[2]?.image?.data?.attributes?.url}` ||
                                ConnitionImg
                          }
                          alt={
                            selectedButton === "AI"
                              ? "AI Service"
                              : selectedService.heading
                          }
                          className="w-full h-auto rounded-[25px]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Services;
