/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { ReactTyped } from "react-typed";
import { motion } from "framer-motion";
import AISearchBot from "../components/atom/AISearchBot";
import { getFromLocal, removeFromLocal, saveToLocal } from "../utils/storage";

import { SearchBotHeader } from "../components/atom/SearchBotHeader";
import { useWebSocketContext } from "../components/WebSocketProvider";
import SkeletonLoader from "../components/atom/SkeletonLoader";
import GoogleLoginForm from "../components/molecule/forms/GoogleLoginForm";
import SideBar from "../components/atom/SideBar";

import SenaWhiteFlag from "../assets/icon/senaWhiteFlag.svg";
import DocIcon from "../assets/image/docIcon.png";
import CognitiveIcon from "../assets/image/cognitiveIcon.png";
import CommunicationIcon from "../assets/image/communicationIcon.png";
import MemorySvg from "../assets/svg/MemorySvg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { newResponse, storeChatData } from "../features/chatData/chatDataSlice";
import generatePDF from "react-to-pdf";
import { hasNonEmptyValue } from "../utils";

let newChat;
let animateNewMessage = false;

const suggestionData = [
  {
    icon: DocIcon,
    suggestionQuestion: "Create a data structure of generated leads",
  },
  {
    icon: CognitiveIcon,
    suggestionQuestion: "All About cognitive and its roles",
  },
  {
    icon: CommunicationIcon,
    suggestionQuestion: "How communication can help ne overall",
  },
];

export const ChatBot = ({ url }) => {
  let userStatus = getFromLocal("userLoggedIn");
  const responses = useSelector((state) => state.chatter.chatResData);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // const [responses, setResponses] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  // const [chatSection, setChatSection] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [query, setQuery] = useState("");
  const location = useLocation();
  const bottomRef = useRef(null);

  // const [showBoxes, setShowBoxes] = useState(true);
  const [selectedBox, setSelectedBox] = useState(null);
  const [animating, setAnimating] = useState(false); // For tracking the animation state
  const [botDataIsLoading, setBotDataIsLoading] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  // const [isSelectedBoxStore, setIsSelectedBoxStore] = useState([]);
  // const [stickyButtonIndex, setStickyButtonIndex] = useState(null);
  // const [user, setUser] = useState(null);
  const [loginForm, setLoginForm] = useState(false);
  const [memoryData, setMemoryData] = useState([]);
  const [sideBar, setSideBar] = useState(false);
  const [memoryUpdatedQuestion, setMemoryUpdatedQuestion] = useState("");
  const [contactQuestion, setContactQuestion] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const sideBarRef = useRef(null);
  const pdfRef = useRef();

  const { receivedMessage, uid, sendMessage } = useWebSocketContext();

  // Animation for selected boxes
  const selectedBoxAnimation = {
    initial: { x: 0, y: 0, opacity: 1 },
    animate: {
      x: [0, 0, -900],
      y: [0, 0, 120],
      opacity: [1, 0.2, 0],
      scale: [1, 0.3],
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  // for Animation
  const waveAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 6, // Decrease damping for a faster animation
        stiffness: 200, // Increase stiffness for a quicker animation
        duration: 0.5,
      },
    },
  };

  // Animation for moving suggestion to question place
  const moveToQuestionPosition = {
    initial: { x: 0, y: 0, opacity: 1 },
    animate: {
      x: [60, 0, -900],
      y: [0, 60, 120],
      opacity: [1, 0.9, 0],
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  // Handle form submit from input box
  const handleSubmit = (text) => {
    userStatus = getFromLocal("userLoggedIn");
    if (!userStatus) {
      setLoginForm(true);
      saveToLocal("query", text);
      return;
    }
    setFirstRender(false);
    animateNewMessage = true;
    const newResponse = {
      question: text,
      answer: "",
    };
    // setResponses((prev) => [...prev, newResponse]);
    dispatch(storeChatData(newResponse));
    setSelectedQuestion(text);
    // setChatSection(true);
    // setShowBoxes(false);
    setBotDataIsLoading(true);
    setSkeletonLoader(true);
    try {
      sendMessage(
        JSON.stringify({
          event: "DATA",
          uid: uid,
          data: text,
        })
      );
      console.log("Message sent:", text);
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  useEffect(() => {
    const searchQuery = getFromLocal("query");
    if (searchQuery && userStatus) {
      handleSubmit(searchQuery);
      removeFromLocal("query");
      // navigate(location?.pathname, { state: { reload: true } });
    }
  }, [userStatus]);

  // generate pdf

  const generatePdfHandler = () => {
    generatePDF(pdfRef, { filename: "senaAiChat.pdf" });
  };

  // Get the passed question from the location state
  useEffect(() => {
    if (location.state && location.state.question) {
      setContactQuestion(location.state.question);
    }
  }, [location.state]);

  // Submit the contactQuestion once, preventing further resubmissions
  useEffect(() => {
    if (contactQuestion && !isSubmitted) {
      handleSubmit(contactQuestion);
      setIsSubmitted(true);
    }
  }, [contactQuestion, isSubmitted]);

  useEffect(() => {
    let parsedMetadata;
    if (typeof receivedMessage.metadata === "string") {
      try {
        parsedMetadata = JSON.parse(receivedMessage.metadata);
      } catch (error) {
        console.error("Error parsing metadata:", error);
        parsedMetadata = null;
      }
    }
    if (receivedMessage.message !== "MEMORY") {
      newChat = responses.map((response) =>
        response.question === selectedQuestion
          ? {
              ...response,
              answer: receivedMessage.message,
              memory: false,
            }
          : response
      );

      setSelectedQuestion("");
      setBotDataIsLoading(false);
    }

    if (
      receivedMessage.message === "MEMORY" &&
      typeof parsedMetadata === "object"
    ) {
      setMemoryData([{ memoryData: parsedMetadata }]);
      setBotDataIsLoading(false);
      setMemoryUpdatedQuestion(selectedQuestion);
      if (newChat?.length && hasNonEmptyValue(parsedMetadata)) {
        const copyChat = [...newChat];
        const lastElem = copyChat?.pop();
        const modifiedElem = { ...lastElem, memory: true };
        newChat = [...copyChat, modifiedElem];
      }
    }
    dispatch(newResponse(newChat));
  }, [receivedMessage]);

  //scroll to the bottom when new responses are added
  useEffect(() => {
    if (bottomRef.current || (loginForm && !userStatus)) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [responses, loginForm]);

  const handleDismissMemoryUpdate = () => {
    setSideBar(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const question = searchParams?.get("ques");
    if (question) {
      handleSubmit(question);
      window?.history?.replaceState({}, "", window?.location?.pathname);
    }
  }, [searchParams?.get("ques")]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        setSideBar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className=" w-full flex relative overflow-x-hidden">
        <motion.div
          className="h-[100vh] relative overflow-auto"
          initial={{ width: "100vw" }}
          animate={sideBar === true ? { width: "80vw" } : { width: "100vw" }}
          transition={{ duration: 1, ease: "backInOut" }}
        >
          <SearchBotHeader setSideBar={setSideBar} sideBar={sideBar} />
          <div className="px-5 md:px-[40px] lg:px-[71px] relative">
            {/* Selected Box */}
            {selectedBox !== null && animating && (
              <motion.div
                className="flex justify-center mt-5"
                variants={selectedBoxAnimation}
                initial="initial"
                animate="animate"
              >
                <div className="flex flex-col gap-2.5 w-full min-h-[165px] cursor-pointer md:w-72 bg-[#5757571a] h-fit p-6 lg:p-10 rounded-[25px] border border-white">
                  <div className="">
                    <img src={suggestionData[selectedBox].icon} alt="docIcon" />
                  </div>
                  <motion.p
                    className="font-sora text-[16px] font-normal leading-[28px] text-left"
                    variants={moveToQuestionPosition}
                    initial="initial"
                    animate="animate"
                  >
                    {suggestionData[selectedBox].suggestionQuestion}
                  </motion.p>
                </div>
              </motion.div>
            )}

            {/* Send "Hi" message to the bot */}
            {firstRender && responses.length === 0 && (
              <div className={loginForm && !userStatus ? "" : "mt-10"}>
                {loginForm && !userStatus ? null : (
                  <h1 className="font-sora text-[18px] md:text-[25px] lg:text-[25px] font-semibold leading-[35px] md:leading-[40px] lg:leading-[40px] text-white">
                    <ReactTyped
                      strings={[
                        "Hello! It's wonderful to connect with you. To kick things off, I’d love to hear about your goals. Are there any challenges you’re currently facing that you think AI could help with. What’s on your mind?",
                      ]}
                      typeSpeed={1}
                      backSpeed={10}
                      showCursor={false}
                    />
                  </h1>
                )}
              </div>
            )}

            {/* Answer and Question */}
            {responses?.map((response, index) => (
              <div
                key={index}
                className={`flex flex-col lg:flex-row items-start gap-10 ${
                  index > 0
                    ? "border-t border-gray-300 pt-6 pb-[20px] mt-6"
                    : ""
                }`}
              >
                <div className="flex flex-col justify-center gap-[44px] w-full">
                  <motion.h1
                    className="font-sora text-[18px] md:text-[25px] lg:text-[25px] font-semibold leading-[35px] md:leading-[40px] lg:leading-[40px] text-white"
                    variants={waveAnimation}
                    initial="hidden"
                    animate="visible"
                  >
                    {response.question}
                  </motion.h1>
                  <div className="flex flex-col gap-[31px]">
                    <div className="flex gap-5">
                      <div className=" p-2 border border-white rounded-full h-min">
                        <img
                          src={SenaWhiteFlag}
                          alt="senaWhiteFlag"
                          className="w-[12px] h-auto"
                        />
                      </div>
                      <div>
                        <div className="">
                          {response.memory && (
                            <button
                              className="my-1 text-[#9B9B9B] flex items-center gap-1 text-sm font-semibold outline-none"
                              onClick={handleDismissMemoryUpdate}
                            >
                              <MemorySvg />
                              Memory updated
                            </button>
                          )}
                        </div>

                        <p className="font-sora text-[18px] font-[500] leading-[29.97px]">
                          Answer
                        </p>
                      </div>
                    </div>
                    <div className="py-9 px-5 rounded-[25px] bg-[#2F2F2F] font-sora text-[16px] font-normal leading-[31.2px]">
                      {responses?.length - 1 === index ? (
                        skeletonLoader && !response?.answer ? (
                          <SkeletonLoader />
                        ) : animateNewMessage ? (
                          <ReactTyped
                            key={response?.answer}
                            strings={[response?.answer]}
                            typeSpeed={1}
                            backSpeed={10}
                            showCursor={false}
                            onComplete={() =>
                              (animateNewMessage = !animateNewMessage)
                            }
                          />
                        ) : (
                          <p>{response?.answer}</p>
                        )
                      ) : (
                        <p>{response?.answer}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div style={{ paddingBottom: "100px" }} ref={bottomRef}></div>

            {/* Login with Google popup */}
            {loginForm && !userStatus ? <GoogleLoginForm /> : null}

            <div className="w-full fixed z-50 left-0 bottom-[3vh] md:bottom-[5vh] lg:bottom-10">
              {/* Sticky Buttons */}
              {/* <div className="mb-4">
              <div className="flex flex-col md:flex-row gap-2 justify-center items-center">
                {filteredSuggestionData.map((question, index) => (
                  <motion.div key={index} className="relative group">
                    <motion.button
                      className="bg-[#2B2B2B] text-[14px] font-[600] p-3 md:px-[15px] md:py-[8px] rounded-[25px] text-white border border-1 border-[#666666] relative"
                      onClick={() =>
                        handleStickyButton(index, question.suggestionQuestion)
                      }
                      initial="initial"
                      animate={
                        stickyButtonIndex === index ? "animate" : "initial"
                      }
                      variants={stickyClickAnimation}
                      onAnimationComplete={() => {
                        setStickyButtonIndex(null);
                      }}
                    >
                      {truncateQuestion(question.suggestionQuestion)}
                    </motion.button>

                    Tooltip: Display full text on hover 
                    <span className="absolute hidden group-hover:block bg-black text-white text-sm p-2 rounded-lg w-max left-1/2 transform -translate-x-1/2 mt-2 z-10">
                      {question.suggestionQuestion}
                    </span>
                  </motion.div>
                ))}
              </div>
              {/* )} */}
              {/* </div> */}

              <AISearchBot
                query={query}
                setQuery={setQuery}
                onSubmit={handleSubmit}
                botDataIsLoading={botDataIsLoading}
              />
            </div>
          </div>
        </motion.div>

        <motion.div
          className="w-[80vw] md:w-[50vw] lg:w-[25vw] h-[100vh] absolute top-0 right-0"
          initial={{
            right: "-80vw",
            md: "-70vw",
            lg: "-25vw",
          }}
          animate={
            sideBar
              ? { right: "0vw" }
              : {
                  right: "-80vw",
                  md: "-70vw",
                  lg: "-25vw",
                }
          }
          transition={{ duration: 1, ease: "backInOut" }}
          ref={sideBarRef}
        >
          <SideBar
            memoryData={memoryData}
            setSideBar={setSideBar}
            generatePdf={generatePdfHandler}
          />
        </motion.div>
      </div>
      <div
        className="pdf-template p-5 bg-[#1a1a1a] fixed -left-[20000px]"
        ref={pdfRef}
        style={{
          width: "1920px",
        }}
      >
        <div className="flex items-center justify-center relative z-[9999] mb-10">
          <SenaLogo />
        </div>
        <h1 className="font-sora text-[25px] font-semibold leading-10 text-white mt-6">
          Hello! It's wonderful to connect with you. To kick things off, I’d
          love to hear about your goals. Are there any challenges you’re
          currently facing that you think AI could help with. What’s on your
          mind?
        </h1>
        {responses?.map((response, index) => (
          <div
            key={index}
            className={`flex flex-row items-start gap-10 mt-5 ${
              index > 0 ? "border-t border-gray-300 pt-6 pb-[20px] mt-6" : ""
            }`}
          >
            <div className="flex flex-col justify-center gap-[44px] w-full">
              <h2 className="font-sora text-[25px] font-semibold leading-[40px] text-white">
                {response.question}
              </h2>
              <div className="flex flex-col gap-[31px]">
                <div className="flex gap-5">
                  <div className="p-4 border border-white rounded-full h-min">
                    <SenaFlag />
                  </div>
                  <div>
                    <div className="">
                      {response.memory && (
                        <button className="my-1 text-[#9B9B9B] flex items-center gap-1 text-sm font-semibold outline-none">
                          <MemorySvg />
                          Memory updated
                        </button>
                      )}
                    </div>

                    <p className="font-sora text-white text-[18px] font-[500] leading-[29.97px]">
                      Answer
                    </p>
                  </div>
                </div>
                <div className="py-9 px-5 rounded-[25px] bg-[#2F2F2F] font-sora text-[16px] text-white font-normal leading-[31.2px]">
                  <p>{response?.answer}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const SenaLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="35"
      viewBox="0 0 140 35"
      fill="none"
    >
      <path
        d="M0 34.5L1.7 28.5H7.7L6.1 34.5H0Z"
        fill="url(#paint0_linear_5_39)"
      />
      <path
        d="M7.5 28.6L9.9 20.2H18.3L16 28.6H7.5Z"
        fill="url(#paint1_linear_5_39)"
      />
      <path
        d="M18.1992 20.2001L19.8992 14.1001H25.8992L24.2992 20.2001H18.1992Z"
        fill="url(#paint2_linear_5_39)"
      />
      <path
        d="M15.5996 14.2L16.8996 9.80005H21.2996L20.0996 14.2H15.5996Z"
        fill="url(#paint3_linear_5_39)"
      />
      <path
        d="M23.4004 7.30005L24.6004 2.80005H29.0004L27.8004 7.30005H23.4004Z"
        fill="url(#paint4_linear_5_39)"
      />
      <path
        d="M5.59961 20.1999L6.79961 15.8999H10.9996L9.89961 20.1999H5.59961Z"
        fill="url(#paint5_linear_5_39)"
      />
      <path
        d="M13.9004 10L14.7004 7H17.7004L16.9004 10H13.9004Z"
        fill="url(#paint6_linear_5_39)"
      />
      <path
        d="M28.6992 3L29.4992 0H32.5992L31.6992 3H28.6992Z"
        fill="url(#paint7_linear_5_39)"
      />
      <path
        d="M25.9004 14.2L26.7004 11.2H29.7004L28.9004 14.2H25.9004Z"
        fill="url(#paint8_linear_5_39)"
      />
      <path
        d="M38.5992 24.85C38.5992 24.65 38.7992 24.45 38.9992 24.45H57.4992C58.7992 24.45 59.7992 23.85 60.0992 22.65C60.5992 20.75 58.9992 18.95 57.0992 18.95H45.2992C41.8992 18.95 39.3992 16.85 38.7992 13.65C38.0992 9.44995 41.4992 5.64995 45.7992 5.64995H63.7992C63.9992 5.64995 64.1992 5.84995 64.1992 6.04995V9.14995C64.1992 9.34995 63.9992 9.54995 63.7992 9.54995H45.6992C43.8992 9.54995 42.3992 11.05 42.5992 12.85C42.7992 14.25 43.7992 15.05 45.2992 15.05H56.9992C61.2992 15.05 64.7992 18.85 63.9992 23.15C63.3992 26.35 60.8992 28.45 57.4992 28.45H38.9992C38.7992 28.45 38.5992 28.25 38.5992 28.05V24.85Z"
        fill="#EDEDED"
      />
      <path
        d="M70.0988 28.35C68.8988 28.35 66.1988 28.1499 66.1988 24.5499V9.54995C66.1988 5.84995 68.9988 5.54995 70.0988 5.54995H88.8988C89.0988 5.54995 89.2988 5.74995 89.2988 5.94995V9.14995C89.2988 9.34995 89.0988 9.54995 88.8988 9.54995H70.4988C70.2988 9.54995 70.0988 9.74995 70.0988 9.94995V14.75C70.0988 14.95 70.2988 15.15 70.4988 15.15H88.8988C89.0988 15.15 89.2988 15.3499 89.2988 15.5499V18.65C89.2988 18.85 89.0988 19.0499 88.8988 19.0499H70.4988C70.2988 19.0499 70.0988 19.2499 70.0988 19.4499V24.15C70.0988 24.35 70.2988 24.5499 70.4988 24.5499H88.8988C89.0988 24.5499 89.2988 24.7499 89.2988 24.9499V28.0499C89.2988 28.2499 89.0988 28.4499 88.8988 28.4499H70.0988V28.35Z"
        fill="#EDEDED"
      />
      <path
        d="M113.998 5.95005C114.198 5.95005 114.398 6.15005 114.398 6.35005V25.25C114.398 27.65 113.198 28.95 111.698 28.95C110.798 28.95 109.698 28.45 108.698 27.25L95.8984 12.05C95.6984 11.75 95.1984 11.95 95.1984 12.25V28.25C95.1984 28.45 94.9984 28.65 94.7984 28.65H91.6984C91.4984 28.65 91.2984 28.45 91.2984 28.25V9.35005C91.2984 6.95005 92.4984 5.65005 93.9984 5.65005C94.8984 5.65005 95.9984 6.15005 96.9984 7.35005L109.898 22.65C110.098 22.95 110.598 22.75 110.598 22.45V6.45005C110.598 6.25005 110.798 6.05005 110.998 6.05005H113.998V5.95005Z"
        fill="#EDEDED"
      />
      <path
        d="M139.298 28.65H135.098C134.998 28.65 134.898 28.65 134.798 28.55L120.898 12.05C120.698 11.75 120.198 11.95 120.198 12.25V28.25C120.198 28.45 119.998 28.65 119.798 28.65H116.698C116.498 28.65 116.298 28.45 116.298 28.25V9.35005C116.298 6.95005 117.498 5.65005 118.998 5.65005C119.898 5.65005 120.998 6.15005 121.998 7.35005L139.498 28.15C139.798 28.35 139.598 28.65 139.298 28.65Z"
        fill="#EDEDED"
      />
      <rect
        x="130.119"
        y="17.9301"
        width="3.96289"
        height="10.4668"
        transform="rotate(90 130.119 17.9301)"
        fill="#EDEDED"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_39"
          x1="0"
          y1="31.4812"
          x2="7.7171"
          y2="31.4812"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_39"
          x1="7.5304"
          y1="24.3917"
          x2="18.3264"
          y2="24.3917"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_39"
          x1="18.2219"
          y1="17.1242"
          x2="25.9346"
          y2="17.1242"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5_39"
          x1="15.6412"
          y1="12.0274"
          x2="21.2845"
          y2="12.0274"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5_39"
          x1="23.3591"
          y1="5.05715"
          x2="29.0024"
          y2="5.05715"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5_39"
          x1="5.63961"
          y1="18.0342"
          x2="11.0333"
          y2="18.0342"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5_39"
          x1="13.8995"
          y1="8.5197"
          x2="17.7416"
          y2="8.5197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5_39"
          x1="28.7101"
          y1="1.5079"
          x2="32.5522"
          y2="1.5079"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5_39"
          x1="25.8693"
          y1="12.7343"
          x2="29.7114"
          y2="12.7343"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="5.13517e-07" stopColor="#FAB83E" />
          <stop offset="1" stopColor="#F9E633" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const SenaFlag = () => {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6237 11.5013L14.024 3.83374H22.6429L20.4609 11.5013H11.6237Z"
        fill="white"
      />
      <path
        d="M22.6417 3.83377L23.951 0H28.2059L27.1149 3.83377H22.6417Z"
        fill="white"
      />
      <path
        d="M15.2216 26.3688L20.022 11.5013H36.8236L32.6777 26.3688H15.2216Z"
        fill="white"
      />
      <path
        d="M0.824219 36L4.20635 25.7143H15.662L12.8253 36H0.824219Z"
        fill="white"
      />
    </svg>
  );
};
