import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

import Header from "../components/atom/Header";
import { Footer } from "../components/atom/Footer";

const BlogDetail = () => {
  const [blogData, setBlogData] = useState(null);
  const { id } = useParams();

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (id) {
      // Fetch the blog details by ID from Strapi
      const fetchBlogData = async () => {
        try {
          const response = await fetch(
            `${baseUrl}/api/articles/${id}?populate=*`
          );
          const data = await response.json();
          setBlogData(data?.data);
        } catch (error) {
          console.error("Error fetching blog data:", error);
        }
      };

      fetchBlogData();
    }
  }, [id]);

  if (!blogData) {
    return <p>Loading...</p>;
  }

  const { Content, Image } = blogData.attributes;

  return (
    <div className="appContainer">
      <Header />
      <div className="w-full">
        <div className="sectionContainer max-w-[1700px] responsiveWidth">
          <div className="flex flex-col gap-20">
            {/* Blog title */}
            <div className="pt-[40px] md:pt-[84px]">
              <div className="flex flex-col lg:flex-row gap-5 justify-between md:items-center">
                <h2 className="font-sora text-left text-[44px] md:text-[52px] lg:text-[76px] font-semibold leading-[40.4px] md:leading-[45.52px] lg:leading-[55.44px] ">
                  {blogData.attributes.Title || "Blog Details"}
                </h2>
                {/* <p className="w-full md:w[60vw] lg:w-[20vw] font-sora text-left md:text-center lg:text-left text-[12px] md:text-[16px] font-normal leading-[20.16px]">
                  Find our latest blogs here, Read about our latest product and
                  research announcements
                </p> */}
              </div>
            </div>

            <div className="flex flex-col gap-5">
              {/* Blog image */}
              {Image?.data && (
                <img
                  src={`${baseUrl}${Image.data[0].attributes.url}`}
                  alt="Blog Image"
                  className="w-full h-[500px] rounded-[25px] object-cover"
                />
              )}

              {/* Blog content rendered using BlocksRenderer */}
              <div className="font-sora text-[13px] md:text-[22px] md:w-[100%] leading-[30px] lg:w-[90%]">
                <BlocksRenderer content={Content} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
