import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const BlogGrid = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const pageSize = 6;

  const handleBlogClick = (id) => {
    navigate(`/blogDetail/${id}`);
  };

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchBlogs = (page) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/articles?populate[Image][fields][0]=url&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[isFeatured]=false`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (Array.isArray(response.data.data)) {
          setBlogs(response.data.data);
          setPageCount(response.data.meta.pagination.pageCount);
        } else {
          console.error("Data is not an array:", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-full">
      <div className="sectionContainer max-w-[1700px] responsiveWidth">
        <div className="border border-white" />
        <div className="flex flex-col gap-[60px] py-[54px]">
          <h2 className="font-sora text-[36px] md:text-[52px] lg:text-[60px] font-semibold leading-[50.4px] md:leading-[65.52px] lg:leading-[75.6px] text-left">
            All Blogs
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
            {Array.isArray(blogs) && blogs.length > 0 ? (
              blogs.map((blog, index) => {
                const { attributes } = blog;
                const imageUrl = attributes?.Image?.data?.[0]?.attributes?.url;
                const contentText = attributes?.title || "";

                return (
                  <div
                    key={blog.id}
                    onClick={() => handleBlogClick(blog.id)}
                    className="cursor-pointer"
                  >
                    {imageUrl && (
                      <img
                        src={`${baseUrl}${imageUrl}`}
                        alt={`Blog Image ${index + 1}`}
                        className="w-full h-[170px] md:h-[175px] lg:h-[288px] rounded-[25px] object-cover"
                      />
                    )}
                    <p className="text-[13px] md:text-[18px] md:w-[100%] lg:w-[90%]">
                      {contentText}
                    </p>
                  </div>
                );
              })
            ) : (
              <p>No blogs available</p>
            )}
          </div>
        </div>

        {/* Pagination Controls */}
        <div className="w-full flex justify-center pt-[40px] md:pt-[40px]">
          <div className="flex gap-2">
            {/* Previous Button */}
            <button
              className={`bg-[#000000] text-[14px] md:text-[16px] font-[600] p-3 md:px-[20px] md:py-[10px] rounded-[8px] text-[#ffffff] transition-shadow duration-300 ${
                currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>

            {/* Page Numbers */}
            {[...Array(pageCount)].map((_, i) => (
              <button
                key={i}
                onClick={() => handlePageClick(i + 1)}
                className={`px-3 py-2 text-[14px] md:text-[16px] font-[600] rounded-[8px] ${
                  currentPage === i + 1
                    ? "bg-[#F7D504] text-[#1A1A1A]"
                    : "bg-white text-gray-600"
                }`}
              >
                {i + 1}
              </button>
            ))}

            {/* Next Button */}
            <button
              className={`bg-[#000000] text-[14px] md:text-[16px] font-[600] p-3 md:px-[20px] md:py-[10px] rounded-[8px] text-[#ffffff] transition-shadow duration-300 ${
                currentPage === pageCount ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleNextPage}
              disabled={currentPage === pageCount}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
